<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="8" class="pa-0">
      <card-initializing
        v-if="isInitializing"
        :text="`Loading Account Information`"
      />
      <template v-else>
        <v-card
          class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
          max-width="100%"
          outlined
        >
          <card-toolbar
            :icon="ACCOUNT_SETTINGS_ICON"
            button-mode
            title="Account Information"
          >
            <v-spacer />
            <tooltip-fab-icon-button
              v-if="isMobileSize"
              :icon-name="PASSWORD_RESET_ICON"
              :loading="resetting"
              @click="sendPasswordReset"
              bottom
              class="mr-4"
              color="secondary"
              icon
              text="Reset Password"
            />
            <v-btn
              v-else
              :loading="resetting"
              @click="sendPasswordReset"
              class="mr-4"
              color="secondary"
              text
            >
              Reset Password
            </v-btn>

            <tooltip-fab-icon-button
              v-if="isMobileSize"
              :icon-name="SIGN_OUT_ICON"
              @click="signOut"
              bottom
              color="error"
              icon
              text="Sign-Out"
            />
            <v-btn
              v-else
              @click="signOut"
              color="error"
              text
            >
              Sign-Out
            </v-btn>
          </card-toolbar>

          <v-card-text class="mt-n4 mb-0 pb-0">
            <p :class="`${DEFAULT_TEXT}`">Your personal account settings.</p>
          </v-card-text>

          <v-list
            class="pb-0"
            flat
            subheader
            three-line
          >
            <v-subheader class="primary--text">Profile</v-subheader>

            <v-list-item-group active-class="">
              <v-list-item>
                <template v-slot:default>
                  <v-list-item-content>
                    <v-list-item-title>ID</v-list-item-title>
                    <v-list-item-subtitle>Account ID</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-sheet max-width="100" class="pl-8 text-right mr-n4">
                    <v-list-item-action class="ma-0">{{userProfile.id}}</v-list-item-action>
                  </v-sheet>
                </template>
              </v-list-item>
              <v-list-item>
                <template v-slot:default>
                  <v-list-item-content>
                    <v-list-item-title>Name</v-list-item-title>
                    <v-list-item-subtitle>Name on the account.</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-sheet max-width="400" class="pl-8 text-right">
                    <v-list-item-action class="ma-0">{{ userProfile.name }}</v-list-item-action>
                  </v-sheet>
                </template>
              </v-list-item>

              <v-list-item>
                <template v-slot:default>
                  <v-list-item-content>
                    <v-list-item-title>Email</v-list-item-title>
                    <v-list-item-subtitle>Email used to log into the account and receive communications.</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-sheet max-width="400" class="pl-8 text-right">
                    <v-list-item-action class="ma-0">{{ userProfile.email }}</v-list-item-action>
                  </v-sheet>
                </template>
              </v-list-item>

              <v-list-item>
                <template v-slot:default>
                  <v-list-item-content>
                    <v-list-item-title>Account Activation</v-list-item-title>
                    <v-list-item-subtitle>Activation status for your account.</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox
                      v-model="userProfile.is_active"
                      disabled
                    />
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-list-item>
                <template v-slot:default>
                  <v-list-item-content>
                    <v-list-item-title>Admin Account</v-list-item-title>
                    <v-list-item-subtitle>Whether your account has administrative privileges (creating and modifying InvisMI iCare accounts).</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox
                      v-model="userProfile.is_admin"
                      disabled
                    />
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>

        <v-card
          v-if="cachedUserProfile.is_admin"
          class="mx-xs-0 px-xs-0 py-4 mx-md-auto px-md-4 mt-4"
          max-width="100%"
          outlined
        >
          <card-toolbar
            title="iCare Dashboard Accounts"
            button-mode
          >
            <v-spacer />
            <tooltip-fab-icon-button
              v-if="isMobileSize"
              @click="newAccount"
              bottom
              color="success"
              icon
              :icon-name="ADD_ACCOUNT_ICON"
              text="New Account"
            />
            <v-btn
              v-else
              @click="newAccount"
              color="success"
              text
            >
              <v-icon left>{{ ADD_ACCOUNT_ICON }}</v-icon>
              New Account
            </v-btn>
          </card-toolbar>

          <v-card-text class="mt-n4 mb-0 pb-0">
            <p :class="`${DEFAULT_TEXT}`">View and modify an iCare Dashboard account by clicking on it.</p>
          </v-card-text>
          
          <v-simple-table class="mx-4">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ID
                  </th>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-ripple
                  v-for="(item, i) in userProfileList"
                  :key="item.id"
                  @click="editAccount(i)"
                  style="cursor: pointer;"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    {{ item.email }}
                    <v-tooltip v-if="!item.is_active" top>
                      <template v-slot:activator="{ on }">
                        <v-icon class="error--text" right v-on="on">{{ DISABLED_ICON }}</v-icon>
                      </template>
                      <span>Account Disabled</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </template>
    </v-col>

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      :fullscreen="isMobileSize"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
      max-width="600px"
    >
      <v-card
        class="pb-6 px-0"
        :loading="saving"
        tile
      >
        <template slot="progress">
          <v-progress-linear
            color="success"
            indeterminate
          ></v-progress-linear>
        </template>

        <card-toolbar
          :disableSave="!valid || isEqual(userProfileList[config.index], config.payload)"
          :title="config.title"
          @close="closeDialog()"
          @save-data="saveData(config)"
          button-mode
          class="mx-3 my-0 pt-4"
          include-close
          include-save
        />
        <v-card-text class="pb-2 pt-8 px-4">
          <v-container class="px-2 py-0">
            <v-form
              v-model="valid"
              @submit.prevent
            >
              <v-row
                v-for="(field, j) in config.fields"
                :key="field.property"
              >
                <v-col>
                  <v-text-field
                    v-if="field.type === 'text' || field.type === 'password'"
                    v-model="config.payload[field.property]"
                    :append-icon="field.appendIcon"
                    :counter="field.counter"
                    :error-messages="getErrorMessages(field.property)"
                    :label="field.label"
                    :messages="field.readonly ? ['Read only'] : []"
                    :prepend-icon="field.prependIcon"
                    :readonly="field.readonly"
                    :rules="field.rules"
                    :type="field.type"
                    @click:append="field.onAppendClick(j)"
                    @keypress.enter="valid && saveData(config)"
                    class="mx-1"
                    color="primary"
                    dense
                  />
                  <v-checkbox
                    v-if="field.type === 'checkbox'"
                    v-model="config.payload[field.property]"
                    :label="field.label"
                    :messages="['Editable']"
                    class="mt-0"
                    color="primary"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <card-toolbar
          v-if="scrollable"
          :disable-save="!valid || isEqual(userProfileList[config.index], config.payload)"
          @close="closeDialog()"
          @save-data="$emit('save-data')"
          button-mode
          class="mx-3 my-0 pb-4"
          include-close
          include-save
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { cloneDeep, filter, isEqual } from 'lodash'
import { mapActions, mapGetters, } from 'vuex'

import AuthService from '../services/api/auth.service'
import { ICONS, ROUTES, TEXT_COLOR, } from '../constants'
import { push } from '../router'
import { transformError } from '../services/utility.service'

export default {
  components: {
    CardInitializing: () => import('../components/layout/cards/CardInitializing.vue'),
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    TooltipFabIconButton: () => import('../components/input/TooltipFabIconButton.vue'),
  },
  data: () => ({
    isInitializing: false,
    userProfile: null,
    cachedUserProfile: null,
    userProfileList: [],
    resetting: false,

    // Dialog window properties.
    config: {},
    dialog: false,
    saving: false,
    scrollable: false,
    valid: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    emailMismatchMessages () {
      if (this.config.fields.length > 0 && this.config.payload.email && this.config.payload.re_email) {
        if (this.config.payload.email.toString() !== this.config.payload.re_email.toString()) {
          return ['Emails must match.']
        }
      }
      return []
    },
    isMobileSize () {
      return this.breakpoint === 'sm' || this.breakpoint === 'xs'
    },
    passwordMismatchMessages () {
      if (this.config.fields.length > 0 && this.config.payload.password && this.config.payload.re_password) {
        if (this.config.payload.password.toString() !== this.config.payload.re_password.toString()) {
          return ['Passwords must match.']
        }
      }
      return []
    },
  },
  methods: {
    ...mapActions({
      createUser: 'user/createUser',
      errorMessage: 'message/errorMessage',
      fetchUser: 'user/fetchUser',
      fetchUserProfile: 'user/fetchUserProfile',
      resendActivation: 'user/resendActivation',
      resetPassword: 'user/resetPassword',
      resetUser: 'user/resetUser',
      successMessage: 'message/successMessage',
      updateUser: 'user/updateUser',
    }),
    closeDialog () {
      this.dialog = false
    },
    editAccount (index) {
      const endsWithS = this.userProfileList[index].name.slice(-1) === 's'

      this.config = {
        title: endsWithS ? `${this.userProfileList[index].name}' Profile` : `${this.userProfileList[index].name}'s Profile`,
        create: false,
        index,
        fields: [
          { property: 'name', counter: null, label: 'Name', rules: [], type: 'text', appendIcon: null, onAppendClick: null, prependIcon: null, readonly: true, },
          { property: 'email', counter: null, label: 'Email', rules: [], type: 'text', appendIcon: null, onAppendClick: null, prependIcon: null, readonly: true, },
          { property: 'is_active', label: 'Active Account', type: 'checkbox', },
          { property: 'is_admin', label: 'Admin Account', type: 'checkbox', },
          { property: 'resend_activation', label: 'Resend Activation Email', type: 'button', onClick: this.sendResendActivation, },
        ],
        payload: cloneDeep(this.userProfileList[index])
      }

      this.dialog = true
    },
    getErrorMessages (fieldProperty) {
      switch(fieldProperty) {
        case 'email': case 're_email':
          return this.emailMismatchMessages;
        case 'password': case 're_password':
          return this.passwordMismatchMessages;
        default:
          return []
      }
    },
    /**
     * Initialize the constants used in the HTML of this view.
     */
    initTemplateConstants () {
      this.ACCOUNT_SETTINGS_ICON = ICONS.ACCOUNT_SETTINGS
      this.ADD_ACCOUNT_ICON = ICONS.ADD_ACCOUNT
      this.DISABLED_ICON = ICONS.DISABLED
      this.SIGN_OUT_ICON = ICONS.SIGN_OUT
      this.PASSWORD_RESET_ICON = ICONS.PASSWORD_RESET

      this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT

      this.isEqual = isEqual
    },
    async initUserProfiles () {
      // Fetch basic user information if it isn't already there.
      if (!this.user.id) {
        await this.fetchUser()
      }

      try {
        this.userProfile = await this.fetchUserProfile({ id: this.user.id })
        this.cachedUserProfile = cloneDeep(this.userProfile)
        await this.initUserProfileList()
      }
      catch (error) {
        this.errorMessage(`Failed to fetch user profiles. ${transformError(error)}`)
      }
    },
    async initUserProfileList () {
      if (this.cachedUserProfile.is_admin) {
        const userProfileList = await this.fetchUserProfile({ id: '' })
        this.userProfileList = filter(userProfileList, user => user.email !== 'jmahicks@gmail.com')
      }
    },
    newAccount () {
      const ruleRequired = v => !!v || 'Value is required.'
      const rule255Length = v => v && v.toString().length <= 255 || 'Must have 255 characters or fewer.'
      const rule128Length = v => v && v.toString().length <= 128 || 'Must have 128 characters or fewer.'
      const ruleTooSmall = v => v && v.toString().length >= 8 || 'Must have 8 characters or more.'
      const ruleNonNumeric = v => v && !(new RegExp('^[0-9]+$').test(v.toString())) || 'Must not only contain numeric characters.'

      this.config = {
        title: 'New Account',
        create: true,
        fields: [
          { property: 'name', counter: 255, label: 'Name', rules: [ruleRequired, rule255Length], type: 'text', appendIcon: null, onAppendClick: null, prependIcon: ICONS.ACCOUNT_SETTINGS, },
          { property: 'email', counter: 255, label: 'Email', rules: [ruleRequired, rule255Length], type: 'text', appendIcon: null, onAppendClick: null, prependIcon: ICONS.EMAIL, },
          { property: 're_email', counter: 255, label: 'Repeat Email', rules: [ruleRequired, rule255Length], type: 'text', appendIcon: null, onAppendClick: null, prependIcon: ICONS.EMAIL, },
          { property: 'password', counter: 128, label: 'Password', rules: [ruleRequired, rule128Length, ruleTooSmall, ruleNonNumeric], type: 'password', appendIcon: ICONS.SHOW, onAppendClick: this.showPasswordToggle, prependIcon: ICONS.LOCK, showPassword: false,  },
          { property: 're_password', counter: 128, label: 'Repeat Password', rules: [ruleRequired, rule128Length, ruleTooSmall, ruleNonNumeric], type: 'password', appendIcon: ICONS.SHOW, onAppendClick: this.showPasswordToggle, prependIcon: ICONS.LOCK, showPassword: false, },
        ],
        payload: {
          email: '',
          re_email: '',
          name: '',
          password: '',
          re_password: ''
        }
      }

      // Add matching rules for email and password.
      this.config.fields[2].rules.push(v => v && v.toString() === this.config.payload.email || 'Emails must match.')
      this.config.fields[4].rules.push(v => v && v.toString() === this.config.payload.password || 'Passwords must match.')

      this.dialog = true
    },
    saveData () {
      this.saving = true

      if (this.config.create) {
        let payload = cloneDeep(this.config.payload)
        // Remove the repeat email, it's not needed by the end point.
        delete payload.re_email

        this.createUser({ payload })
          .then(response => {
            this.userProfileList.push(response)
            this.successMessage(`Successfully created account for ${payload.name}. Sending activation email.`)
          })
          .catch(error => {
            this.errorMessage(`Failed to create account. ${transformError(error)}`)
          })
          .finally(() => {
            this.saving = false
          })
      }
      else {
        let payload = cloneDeep(this.config.payload)
        
        this.updateUser({ id: payload.id, payload })
          .then(response => {
            if (payload.id === this.userProfile.id) {
              this.initUserProfiles()
            }
            this.initUserProfileList()
            this.successMessage(`Successfully updated account profile for ${payload.name}.`)
          })
          .catch(error => {
            this.errorMessage(`Failed to update account. ${transformError(error)}`)
          })
          .finally(() => {
            this.saving = false
          })
      }
    },
    sendPasswordReset () {
      this.resetting = true
      this.resetPassword({ email: this.userProfile.email })
        .then(response => {
          this.successMessage(`Password reset email sent, check the email associated with this account.`)
        })
        .catch(error => {
          this.errorMessage(`Failed to send password reset email. ${transformError(error)}`)
        })
        .finally(() => {
          this.resetting = false
        })
    },
    showPasswordToggle(index) {
      this.config.fields[index].showPassword = !this.config.fields[index].showPassword
      this.config.fields[index].type = this.config.fields[index].showPassword ? 'text' : 'password'
      this.config.fields[index].appendIcon = this.config.fields[index].showPassword ? ICONS.HIDE : ICONS.SHOW
    },
    signOut () {
      this.signingOut = true
      AuthService.logout()
      this.resetUser()
      this.signingOut = false
      push(ROUTES.SIGN_IN)
    },
  },
  async created() {
    this.isInitializing = true
    
    this.initTemplateConstants()
    await this.initUserProfiles()

    this.isInitializing = false
  },
  beforeUpdate () {
    const element = document.getElementsByClassName('v-dialog--active')[0]
    if (!!element) {
      this.scrollable = element.scrollHeight > element.clientHeight
      return
    }
    this.scrollable = false
  }
}
</script>

<style scoped>
button {
  outline: none;
}
div.v-list {
  cursor: pointer;
}
</style>